<template>
  <g>
    <sodipodi:namedview
      id="namedview11"
      pagecolor="#ffffff"
      bordercolor="#666666"
      borderopacity="1.0"
      inkscape:pageshadow="2"
      inkscape:pageopacity="0.0"
      inkscape:pagecheckerboard="0"
      showgrid="false"
      inkscape:zoom="0.95219708"
      inkscape:cx="793.95328"
      inkscape:cy="561.33337"
      inkscape:window-width="2400"
      inkscape:window-height="1261"
      inkscape:window-x="-9"
      inkscape:window-y="-4"
      inkscape:window-maximized="1"
      inkscape:current-layer="g15"
    /><g
      id="g15"
      inkscape:groupmode="layer"
      inkscape:label="DEV260 Components Drawing Template v1"
      transform="matrix(0.08,0,0,-0.08,0,1122.6667)"
    >
      <g v-show="btnToggle != 2">
        <DevTwoSixty
          ref="pp1"
          v-show="fixtureConfigId == 1"
          :testpointPlot="testpointPlotter"
          :guidePins="guidePins"
          :renderScale="renderScale"
          :svgScale="svgScale"
          :sliderScale="sliderScale"
          :probeElement="probeElement[0]"
          :sideToggle="sideToggle"
          :btnRenderSide="btnRenderSide"
          :showRefs="showRefs"
          :showPlate="showPlate"
          :searchRefString="searchRefString"
          :refSlider="refSlider"
          @toggleHoverText="toggleHoverText"
        />
        <MaTwoSixty
          ref="pp2"
          v-show="fixtureConfigId == 2"
          :testpointPlot="testpointPlotter"
          :guidePins="guidePins"
          :renderScale="renderScale"
          :svgScale="svgScale"
          :sliderScale="sliderScale"
          :probeElement="probeElement[1]"
          :sideToggle="sideToggle"
          :btnRenderSide="btnRenderSide"
          :showRefs="showRefs"
          :showPlate="showPlate"
          :searchRefString="searchRefString"
          :refSlider="refSlider"
          @toggleHoverText="toggleHoverText"
        />
        <IngunMaEleven
          ref="pp3"
          v-show="fixtureConfigId == 3"
          :testpointPlot="testpointPlotter"
          :guidePins="guidePins"
          :renderScale="renderScale"
          :svgScale="svgScale"
          :sliderScale="sliderScale"
          :probeElement="probeElement[2]"
          :sideToggle="sideToggle"
          :btnRenderSide="btnRenderSide"
          :showRefs="showRefs"
          :showPlate="showPlate"
          :searchRefString="searchRefString"
          :refSlider="refSlider"
          @toggleHoverText="toggleHoverText"
        />
      </g>
      <g v-show="btnToggle != 1">
        <PressureDev260
          ref="pressure1"
          v-show="fixtureConfigId == 1"
          :pressurePins="pressurePins"
          :renderScale="renderScale"
          :svgScale="svgScale"
          :sliderScale="sliderScale"
          :probeElement="probeElement[0]"
          :sideToggle="sideToggle"
          :btnRenderSide="btnRenderSide"
          :showRefs="showRefs"
          :showPlate="showPlate"
          :searchRefString="searchRefString"
          :refSlider="refSlider"
          @toggleHoverText="toggleHoverText"
        />
        <PressureMa260
          ref="pressure2"
          v-show="fixtureConfigId == 2"
          :pressurePins="pressurePins"
          :renderScale="renderScale"
          :svgScale="svgScale"
          :sliderScale="sliderScale"
          :probeElement="probeElement[1]"
          :sideToggle="sideToggle"
          :btnRenderSide="btnRenderSide"
          :showRefs="showRefs"
          :showPlate="showPlate"
          :searchRefString="searchRefString"
          :refSlider="refSlider"
          @toggleHoverText="toggleHoverText"
        />
        <PressureMa11
          ref="pressure3"
          v-show="fixtureConfigId == 3"
          :pressurePins="pressurePins"
          :renderScale="renderScale"
          :svgScale="svgScale"
          :sliderScale="sliderScale"
          :probeElement="probeElement[2]"
          :sideToggle="sideToggle"
          :btnRenderSide="btnRenderSide"
          :showRefs="showRefs"
          :showPlate="showPlate"
          :searchRefString="searchRefString"
          :refSlider="refSlider"
          @toggleHoverText="toggleHoverText"
        />
      </g>
      <g id="g63">
        <g id="g65" clip-path="url(#clipPath69)"
          ><path
            d="M 944,472 H 19369"
            style="fill:none;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
            id="path71"/><path
            d="M 19369,472 V 13558 H 944 V 472"
            style="fill:none;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
            id="path73"/><path
            d="M 10157,236 V 708"
            style="fill:none;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
            id="path75"/><path
            d="m 10157,13794 v -472"
            style="fill:none;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
            id="path77"/><path
            d="m 12519,13794 v -236"
            style="fill:none;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
            id="path79"/><path
            d="m 14881,13794 v -236"
            style="fill:none;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
            id="path81"/><path
            d="m 17243,13794 v -236"
            style="fill:none;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
            id="path83"/><path
            d="m 7795,13794 v -236"
            style="fill:none;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
            id="path85"/><path
            d="m 5432,13794 v -236"
            style="fill:none;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
            id="path87"/><path
            d="m 3070,13794 v -236"
            style="fill:none;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
            id="path89"/><path
            d="m 708,7015 h 473"
            style="fill:none;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
            id="path91"/><path
            d="M 708,4755 H 944"
            style="fill:none;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
            id="path93"/><path
            d="M 708,2495 H 944"
            style="fill:none;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
            id="path95"/><path
            d="M 708,9275 H 944"
            style="fill:none;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
            id="path97"/><path
            d="M 708,11535 H 944"
            style="fill:none;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
            id="path99"/><path
            d="m 19605,7015 h -472"
            style="fill:none;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
            id="path101"/><path
            d="M 12519,236 V 472"
            style="fill:none;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
            id="path103"/><path
            d="M 14881,236 V 472"
            style="fill:none;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
            id="path105"/><path
            d="M 17243,236 V 472"
            style="fill:none;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
            id="path107"/><path
            d="M 7795,236 V 472"
            style="fill:none;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
            id="path109"/><path
            d="M 5432,236 V 472"
            style="fill:none;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
            id="path111"/><path
            d="M 3070,236 V 472"
            style="fill:none;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
            id="path113"/><path
            d="m 19605,4755 h -236"
            style="fill:none;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
            id="path115"/><path
            d="m 19605,2495 h -236"
            style="fill:none;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
            id="path117"/><path
            d="m 19605,9275 h -236"
            style="fill:none;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
            id="path119"/><path
            d="m 19605,11535 h -236"
            style="fill:none;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
            id="path121"/></g
      ></g>
      <LegendGroup
        :testpointPlot="testpointPlot"
        :guidePins="guidePins"
        :receptacleGroup="receptacleGroup"
        :probeGroup="probeGroup"
        :guidepinGroup="guidepinGroup"
        :pressurepinGroup="pressurepinGroup"
        :btnToggle="btnToggle"
        :sideToggle="sideToggle"
      />
      <LabelGroup
        :project="project"
        :btnToggle="btnToggle"
        :sideToggle="sideToggle"
      />
    </g>
  </g>
</template>
<script>
export default {
  name: "DrawingGroup",
  props: {
    testpointPlot: Array,
    testpointPlotter: Array,
    guidePins: Array,
    pressurePins: Array,
    receptacleGroup: Array,
    probeGroup: Array,
    guidepinGroup: Array,
    pressurepinGroup: Array,
    renderScale: Number,
    svgScale: Number,
    btnToggle: Number,
    sideToggle: Number,
    btnRenderSide: Number,
    project: Object,
    fixtureConfigId: Number,
    sliderScale: Number,
    probeElement: Array,
    showRefs: Boolean,
    showPlate: Boolean,
    searchRefString: String,
    refSlider: Number,
  },
  components: {
    DevTwoSixty: () => import("./Dev260.vue"),
    IngunMaEleven: () => import("./IngunMa11.vue"),
    MaTwoSixty: () => import("./Ma260.vue"),
    LabelGroup: () => import("./LabelGroup.vue"),
    LegendGroup: () => import("./LegendGroup.vue"),
    PressureDev260: () => import("./PressureDev260.vue"),
    PressureMa260: () => import("./PressureMa26.vue"),
    PressureMa11: () => import("./PressureMa11.vue"),
  },
  methods: {
    setTransform(selection) {
      this.$refs.pp1 && this.$refs.pp1.setTransform(selection);
    },
    toggleHoverText(payload) {
      this.$emit("toggleHoverText", payload);
    },
  },
};
</script>
